import axios from 'axios'

const API = axios.create({
  baseURL: window.location.hostname.includes("flexvastgoedcrm")
  ? "https://server.kantoorflex.nl/api/rest/v2"
  : "https://server.kantoorflex.nl/api/rest/v2",
  timeout: 30000,
});


export default {
  async getCurrentUser() {
    try {
      const response = await API.get(`/customer/get_info`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
   async getUsers() {
    try {
      const response = await API.get(`/users`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  async getKennisItems() {
    try {
      const response = await API.get(`/kennis/user/get_kennis`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  async getPendingAppointments() {
    try {
      const response = await API.get(`/appointment/pending/user/invoices`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  
  
  async getApprovedAppointments() {
    try {
      const response = await API.get(`/appointment/approved/user/invoices`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  
  async getCancelledAppointments() {
    try {
      const response = await API.get(`/appointment/cancelled/user/invoices`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  }
  
}

