<template>
  <div>
    <v-container style="padding-top: 4rem">
      <div>
        <v-btn color="primary" @click="alert = !alert">
          {{ alert ? "Afsluiten" : "Meer informatie" }}
        </v-btn>

        <div style="margin-top:10px">
          <v-alert
            :value="alert"
            color="info"
            dark
            border="top"
            icon="mdi-information-outline"
            transition="scale-transition"
          >
            Voor vragen of advies kunt u een mail sturen naar
            <strong>info@kantoorflex.nl</strong>
          </v-alert>
        </div>
      </div>

      <span v-if="isMorning()">
        <h4>{{ morning }} {{ user.name }}</h4>
      </span>
      <span v-if="isEvening()">
        <h4>{{ evening }} {{ user.name }}</h4>
      </span>
      <span v-if="isNight()">
        <h4>{{ night }} {{ user.name }}</h4>
      </span>

      <div class="row" style="margin-top:10px">
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link class="router-link" :to="{ name: 'Mijngegevens' }">
            <div class="link-box">
              <img src="@/assets/user/user.png" alt="img" />
              <p class="link-box-title">Gegevens</p>
              <p class="link-box-subtitle">Mijn gegevens inzien</p>
            </div>
          </router-link>
        </div>

        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link class="router-link" :to="{ name: 'Reserveringen' }">
            <div class="link-box">
              <img src="@/assets/user/reserveringen.png" alt="img" />
              <p class="link-box-title">Reserveringen beheren</p>
              <p class="link-box-subtitle">Reserveringen bekijken</p>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link class="router-link" :to="{ name: 'Mijnfacturen' }">
            <div class="link-box">
              <img src="@/assets/user/facturen.png" alt="img" />
              <p class="link-box-title">Facturen overzicht</p>
              <p class="link-box-subtitle">Facturen bekijken en downloaden</p>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link
            class="router-link"
            :to="{ name: 'InstellingenBeheren' }"
          >
            <div class="link-box">
              <img src="@/assets/user/settings.png" alt="img" />
              <p class="link-box-title">Instellingen</p>
              <p class="link-box-subtitle">Wachtwoord/Data beheren</p>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <router-link class="router-link" :to="{ name: 'Kennissysteem' }">
            <div class="link-box">
              <img src="@/assets/user/kennis.png" alt="img" />
              <p class="link-box-title">Kennissysteem</p>
              <p class="link-box-subtitle">Informatie bekijken</p>
            </div>
          </router-link>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>

import apiUser from "../api/apiUser"
export default {
  data() {
    return {
      user: [],
      alert: true,
      morning: "Goedemorgen",
      evening: "Goedemiddag",
      night: "Goedeavond",
    };
  },

  async mounted() {
       apiUser.getCurrentUSer().then((data) => {
      this.user = data;
    });
   
  },

  methods: {

    isMorning() {
      return new Date().getHours() < 12 ? true : false;
    },
    isEvening() {
      return new Date().getHours() >= 12 && new Date().getHours() <= 17
        ? true
        : false;
    },
    isNight() {
      return new Date().getHours() >= 17 && new Date().getHours() <= 24
        ? true
        : false;
    },
  },
};
</script>

<style scoped>
.router-link {
  color: black;
  text-decoration: none;
}
.link-box {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 5px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  font-size: 16px;
  text-align: center;
  /* min-height:100%; */
}
.link-box p {
  margin: 0px 0px 10px 0px;
}
.link-box img {
  height: 40px;
  margin-bottom: 10px;
}
.link-box-title {
  color: black;
  font-weight: bold;
  font-size: 18px;
}
.link-box-subtitle {
  color: black;
  font-size: 18px;
  margin-bottom: 10px;
}
</style>
