<template>
  <UserDashboard />
</template>

<script>
import UserDashboard from "@/components/user/UserDashboard";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    UserDashboard,
  },
  metaInfo: {
    title: "Klant Dashboard | Kantoofflex",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>